//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    Notice: () => import("./notice"),
  },
  data() {
    return {
      timer: null,
      count: null,
      codeLoading: false, //获取验证码加载
      timerPhone: null,
      countPhone: null,
      codeLoadingPhone: false, //获取验证码加载

      loading: false,
      isRead: false,
      ruleForm: {
        Name: null,
        Pwd: null,
        Phone: null,
        addres: null,
        code: null,
        phoneCode: null,
      },
      rules: {
        Name: [
          {
            required: true,
            message: "请输入你的姓名",
            trigger: "blur",
          },
        ],
        Pwd: [
          {
            required: true,
            message: "请输入登录密码",
            trigger: "blur",
          },
        ],
        // Name: [
        //   {
        //     required: true,
        //     message: "请输入昵称",
        //     trigger: "blur",
        //   },
        // ],
        Phone: [
          {
            required: true,
            message: "请输入电话号码",
            trigger: "blur",
          },
        ],
        addres: [
          {
            required: true,
            message: "请输入邮箱地址",
            trigger: "blur",
          },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        code: [
          {
            required: true,
            message: "请输入邮箱验证码",
            trigger: "blur",
          },
        ],
        phoneCode: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    isSell() {
      return process.env.VUE_APP_SELL;
    },
    appName() {
      return process.env.VUE_APP_NAME;
    },
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
    clearInterval(this.timerPhone);
    this.timerPhone = null;
  },
  methods: {
    /**
     * 阅读服务
     */
    handleNotice() {
      this.$refs.notice.openDialog();
    },
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    /**
     * 获取邮箱验证码
     */
    handleGetCode() {
      if (this.timer) {
        return;
      }
      this.$refs.ruleForm.validateField("addres", (email_check) => {
        if (email_check) {
          return;
        } else {
          this.codeLoading = true;
          this.$http
            .post("/User/SendEmailVerificationCode.ashx", {
              email: this.ruleForm.addres,
            })
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  showClose: true,
                  message: "验证码发送成功",
                  type: "success",
                });
                this.getCode();
              }
            })
            .finally(() => (this.codeLoading = false));
        }
      });
    },
    /**
     * 获取手机验证码
     */
    handleGetCodePhone() {
      if (this.timer) {
        return;
      }
      this.$refs.ruleForm.validateField("Phone", (email_check) => {
        if (email_check) {
          return;
        } else {
          this.codeLoadingPhone = true;
          this.$http
            .post("/User/SendVerificationCode.ashx", {
              Phone: this.ruleForm.Phone,
            })
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  showClose: true,
                  message: "验证码发送成功",
                  type: "success",
                });
                this.getCodePhone();
              }
            })
            .finally(() => (this.codeLoadingPhone = false));
        }
      });
    },
    getCodePhone() {
      const TIME_COUNT = 60;
      if (!this.timerPhone) {
        this.countPhone = TIME_COUNT;
        this.timerPhone = setInterval(() => {
          if (this.countPhone > 0 && this.countPhone <= TIME_COUNT) {
            this.countPhone--;
          } else {
            clearInterval(this.timerPhone);
            this.timerPhone = null;
          }
        }, 1000);
      }
    },
    /**
     * 点击登录
     */
    handleLogin() {
      this.$router.push("/");
    },
    /**
     * 注册
     */
    register() {
      // if (!this.isRead) {
      //   this.$message({
      //     showClose: true,
      //     message: "请先仔细阅读隐私政策",
      //     type: "error",
      //   });
      //   return;
      // }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/User/Register.ashx", this.ruleForm)
            .then((req) => {
              if (req.res == 0) {
                this.$message({
                  message: "注册成功",
                  type: "success",
                });
                this.$router.push("/");
              }
            })
            .finally(() => (this.loading = false))
            .catch((err) => {
              console.log(err);
            });
        } else {
          return;
        }
      });
    },
  },
};
